import request from '@/utils/request'
//订单一览查询
export function listPage(query) {
    return request({
      url: '/order/listPage',
      method: 'get',
      params:query
    })
  }
//售前建议新增
export function insertPresalesSuggestion(data) {
    return request({
      url: '/presalesSuggestion/insertPresalesSuggestion',
      method: 'post',
      data
    })
  }
//查询详情
export function selectPartnerById(query) {
    return request({
      url: '/presalesSuggestion/selectPartnerById',
      method: 'get',
      params:query
    })
  }
  //售前建议编辑
  export function updatePresalesSuggestion(data) {
    return request({
      url: '/presalesSuggestion/updatePresalesSuggestion',
      method: 'post',
      data
    })
  }
  //售前建议列表\
  export function selectPresalesSuggestionPage(data) {
    return request({
      url: '/presalesSuggestion/selectPresalesSuggestionPage',
      method: 'post',
      data
    })
  }
 //模糊查询所有的行为知识数据
 export function getAllBehaviorLabel(query) {
    return request({
      url: '/presalesSuggestion/getAllBehaviorLabel',
      method: 'get',
      params:query
    })
  }
//模糊查询所有的场景知识数据
export function getAllScenarioBasic(query) {
    return request({
      url: '/presalesSuggestion/getAllScenarioBasic',
      method: 'get',
      params:query
    })
  }
  export function selectByCompanyFullName(query) {
    return request({
      url: '/serverCompany/selectByCompanyFullName',
      method: 'get',
      params:query
    })
  }
 //售前建议上、下架
 export function updateIsShelf(query) {
  return request({
    url: '/presalesSuggestion/updateIsShelf',
    method: 'get',
    params:query
  })
}
//售前建议删除
export function deleteByIds(data) {
  return request({
    url: '/presalesSuggestion/deleteByIds',
    method: 'post',
    data
  })
}
