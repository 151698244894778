var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"input-with"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addbanner()}}},[_vm._v("新增")]),_c('el-button',{attrs:{"type":"info","plain":""},on:{"click":_vm.carouselDelete}},[_vm._v("批量删除")])],1),_c('div',{staticStyle:{"float":"right"}},[_c('div',{staticClass:"seach_box"},[_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":"请输入关联场景名称"},model:{value:(_vm.queryInfo.name),callback:function ($$v) {_vm.$set(_vm.queryInfo, "name", $$v)},expression:"queryInfo.name"}},[_c('el-button',{staticClass:"seach",attrs:{"slot":"append"},on:{"click":_vm.changeSearchangeSearch},slot:"append"},[_vm._v("搜索")])],1)],1)]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"in_table",staticStyle:{"width":"100%"},attrs:{"element-loading-text":"加载中...","element-loading-spinner":"el-icon-loading","data":_vm.listData,"stripe":"","row-key":_vm.getRowKey,"header-cell-style":{ background: '#F8F9FB', color: '#595959' }},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"80px","align":"center","reserve-selection":true}}),_c('el-table-column',{attrs:{"label":"建议编号","width":"100px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s((scope.$index+1)+(_vm.queryInfo.pageNum-1)*_vm.queryInfo.pageSize)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"建议描述","prop":"suggestionDescribe","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.suggestionDescribe))])]}}])}),_c('el-table-column',{attrs:{"label":"企业名称","prop":"companyFullName","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.companyFullName))])]}}])}),_c('el-table-column',{attrs:{"label":"企业行为","prop":"sceneData","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.sceneData))])]}}])}),_c('el-table-column',{attrs:{"label":"应用场景","prop":"behaviorData","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.behaviorData))])]}}])}),_c('el-table-column',{attrs:{"label":"推送时间","width":"180px","prop":"createTime","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.createTime))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","prop":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isShelf==0)?_c('span',{staticClass:"frozen",on:{"click":function($event){return _vm.checkClick(row)}}},[_vm._v("下架")]):_c('span',{staticClass:"frozen",on:{"click":function($event){return _vm.checkClick(row)}}},[_vm._v("上架")]),_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.editclick(row)}}},[_vm._v("编辑")]),_c('span',{staticClass:"frozen",on:{"click":function($event){return _vm.carouselActivate(row)}}},[_vm._v("删除")])]}}])})],1)],1)],1),_c('div',{staticClass:"new_page"},[_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.pageNum,"page-sizes":[10, 30, 50],"page-size":_vm.queryInfo.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }